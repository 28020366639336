import { inject } from '@angular/core';
import { ActionType, Store } from '@ngxs/store';
import { ProcessingState } from './processing.state';

/** Can be used only in injection context */
export const hasBeingPreparedOf = (scope: ActionType[]) =>
  inject(Store).select(ProcessingState.hasBeingPreparedOf(scope));

/** Can be used only in injection context */
export const hasCompletedWithErrorOf = (scope: ActionType[]) =>
  inject(Store).select(ProcessingState.hasCompletedWithErrorOf(scope));
