export interface CookieRecord {
  [cookieKey: string]: string;
}

export const parseCookies = (cookies: string): CookieRecord =>
  cookies.split(';').reduce((acc, item) => {
    const [key, val] = item.trim().split('=');
    return {
      ...acc,
      [key]: val
    };
  }, {});
