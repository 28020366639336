// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// eslint-disable-next-line
import pckg from '../../package.json';

export const environment = {
  production: false,
  api: {
    baseUrl: 'https://test.api.closapp.space',
    storeBaseUrl: 'https://test.api.closapp.space/v1/image/upload',
    ver: '2.4.7',
    appName: 'clos',
    platform: 'web'
  },
  firebase: {
    apiKey: 'AIzaSyCiEr6QoFbgu9ve5gXBXg7FJFABr-juLJ0',
    authDomain: 'clos-34cea.firebaseapp.com',
    databaseURL: 'https://clos-34cea.firebaseio.com',
    projectId: 'clos-34cea',
    storageBucket: 'clos-34cea.appspot.com',
    messagingSenderId: '783858920746',
    appId: '1:783858920746:web:0353732fda7a2c4c05c253',
    measurementId: 'G-22RWH94NRL'
  },
  branchIo: {
    apiKey: 'key_live_ahUU1Rguue3q220r91eWngpdvqpDCqiw'
  },
  amazon: {
    url: 'https://s3.closapp.space/dev/upload'
  },
  revenuecat: {
    url: 'https://api.revenuecat.com/v1/receipts',
    api_key: 'ohMdMzvxPvtGDKZcxwvjkHNJVAvgWOUV'
  },
  version: pckg.version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
