import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsModule, Store } from '@ngxs/store';
import { AuthState } from '@core/auth/state/auth.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { AppHttpInterceptor } from '@core/http/core-intercepter';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { PopupModule } from '@shared/popup/popup.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsDataPluginModule } from '@ngxs-labs/data';

import {
  AngularFireAnalyticsModule,
  APP_VERSION,
  COLLECTION_ENABLED,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalErrorHandler } from '@core/http/global-error-handler';
import { AppUpdateService } from '@shared/services/app-update.service';
import { InformToUseChromeService } from '@shared/services/inform-to-use-chrome.service';
import { RoomsApiModule, PARTICIPANT_STORE_TK, ParticipantStore } from '@shared/modules/rooms/api';
import { UserPreferencesState } from './state/user-preferences';
import { ProcessingState } from './state/processing';
registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    NgxsModule.forRoot([AuthState, UserPreferencesState, ProcessingState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: [AuthState, UserPreferencesState]
    }),
    NgxsDataPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    TranslateModule.forRoot(),
    PopupModule.forRoot(),
    RoomsApiModule.forRoot([
      {
        provide: PARTICIPANT_STORE_TK,
        useFactory: (store: Store): ParticipantStore => ({
          get: () => store.selectSnapshot(AuthState.user)
        }),
        deps: [Store]
      }
    ])
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_VERSION,
      useValue: environment.version
    },
    UserTrackingService,
    ScreenTrackingService,
    {
      provide: COLLECTION_ENABLED,
      useValue: environment.production
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'ru' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(updateService: AppUpdateService, chromeWarning: InformToUseChromeService) {
    updateService.init();
    chromeWarning.init();
  }
}
