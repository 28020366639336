import { ErrorHandler, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Optional() @Inject(PLATFORM_ID) private platformId: string) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message) && isPlatformBrowser(this.platformId)) {
      window.location.reload();
    } else {
      throw error;
    }
  }
}
