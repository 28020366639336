import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isPlatformBrowser } from '@angular/common';
import { PatchUser, RefreshToken, SignIn, SignOut } from './auth.actions';
import { User } from './auth.models';
import { buildAuthCookie } from '../helpers';
import { AuthCookieState } from '../const';

export class AuthStateModel {
  user: User;
  tk: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    tk: null,
    user: null
  }
})
@Injectable()
export class AuthState {
  @Selector()
  static isSignedIn({ tk, user }: AuthStateModel): boolean {
    return !!(tk && user);
  }

  @Selector()
  static token({ tk }: AuthStateModel): string | null {
    return tk;
  }

  @Selector()
  static user({ user }: AuthStateModel): User | null {
    return user;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  @Action(SignIn)
  signIn({ setState }: StateContext<AuthStateModel>, { payload }: SignIn): void {
    const { tk, user } = payload;
    setState({ tk, user });

    if (isPlatformBrowser(this.platformId)) {
      document.cookie = buildAuthCookie(AuthCookieState.signedIn);
    }
  }

  @Action(PatchUser)
  patchUser({ patchState, getState }: StateContext<AuthStateModel>, { payload }: PatchUser): void {
    const { user } = getState();
    patchState({
      user: {
        ...user,
        ...payload
      }
    });
  }

  @Action(SignOut)
  signOut({ setState }: StateContext<AuthStateModel>): void {
    setState({ tk: null, user: null });

    if (isPlatformBrowser(this.platformId)) {
      document.cookie = buildAuthCookie(AuthCookieState.signedOut);
      localStorage.clear();
    }
  }

  @Action(RefreshToken)
  refreshToken({ patchState }: StateContext<AuthStateModel>, { payload }: RefreshToken): void {
    patchState({ tk: payload.tk });
  }
}
