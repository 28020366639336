import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { environment } from '@env/environment';
import { RoomsApiModule } from '../rooms-api.module';

interface IntentionResponse {
  url: string;
  header: {
    'content-length': [string];
  };
}

interface CompleteResponse {
  status: boolean;
}

export type UploadFileType =
  | 'original'
  | 'preview'
  | 'retouch'
  | 'video'
  | 'video_preview'
  | 'avatar';

export interface UploadFileDTO {
  roomId: string;
  type: UploadFileType;
  file: File;
}

@Injectable({
  providedIn: RoomsApiModule
})
export class RoomFilesTransferService {
  private baseUrl = `${environment.api.baseUrl}/v1/image`;

  constructor(private http: HttpClient) {}

  /* eslint-disable @typescript-eslint/naming-convention */
  uploadFileToRoom({ file, roomId: room_id, type }: UploadFileDTO): Observable<CompleteResponse> {
    const image_size = file.size;
    const image_name = file.name;
    return this.http
      .post<IntentionResponse>(`${this.baseUrl}/upload`, null, {
        params: { room_id, type, image_size, image_name }
      })
      .pipe(
        switchMap(({ url }) => this.http.put<void>(url, file)),
        switchMap(() =>
          this.http.post<CompleteResponse>(`${this.baseUrl}/complete`, {
            room_id,
            image_name,
            type
          })
        )
      );
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
