import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthState, AuthStateModel } from '@core/auth/state/auth.state';
import { AuthService } from '@core/auth/services/auth.service';
import { RefreshToken } from '@core/auth/state/auth.actions';
import { PopupService } from '@shared/popup/services/popup.service';
import { DefaultPopupTheme } from '@shared/popup/models';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {
  private tokenStaleState$ = new BehaviorSubject<'actual' | 'staled'>('actual');

  constructor(
    private store: Store,
    private authService: AuthService,
    private readonly popup: PopupService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !req.url.startsWith(environment.api.baseUrl) &&
      !req.url.startsWith(environment.api.storeBaseUrl)
    ) {
      return next.handle(req);
    }

    return next.handle(this.cloneWithCoreHeaders(req)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (this.isErrorWithTokenExpired(err.error)) {
          return this.handleErrorWithTokenExpired(req, next);
        }

        if (err.status === 426) {
          return this.popup
            .alert({
              title: 'The version of the application is outdated, please restart the page',
              okBtn: 'RESTART',
              theme: DefaultPopupTheme.error,
              text: ''
            })
            .pipe(
              tap(() => location.reload()),
              switchMap(() => next.handle(this.cloneWithCoreHeaders(req)))
            );
        }

        return throwError(err.error);
      })
    );
  }

  protected handleErrorWithTokenExpired(
    req: HttpRequest<any>,
    next: HttpHandler,
    state = this.tokenStaleState$.value
  ): Observable<HttpEvent<any>> {
    if (state === 'actual') {
      this.tokenStaleState$.next('staled');
      return this.authService.refreshToken().pipe(
        tap(tk => this.store.dispatch(new RefreshToken({ tk }))),
        tap(() => this.tokenStaleState$.next('actual')),
        switchMap(() => next.handle(this.cloneWithCoreHeaders(req)))
      );
    } else {
      return this.tokenStaleState$.pipe(
        filter(tkState => tkState === 'actual'),
        switchMap(() => next.handle(this.cloneWithCoreHeaders(req)))
      );
    }
  }

  protected isErrorWithTokenExpired(err: any): boolean {
    return err?.code === 101 && err?.description === 'not valid firebase token';
  }

  protected cloneWithCoreHeaders<T>(req: HttpRequest<T>): HttpRequest<T> {
    let headers = this.setAuthorization(req.headers);
    headers = this.setApiVersion(headers);
    headers = this.setApiName(headers);
    headers = this.setPlatform(headers);

    return req.clone({ headers });
  }

  protected setAuthorization(headers: HttpHeaders): HttpHeaders {
    const { tk } = this.store.selectSnapshot<AuthStateModel>(AuthState);

    if (!tk) {
      return headers;
    }

    return headers.set('Authorization', `Bearer ${tk}`);
  }

  protected setApiVersion(headers: HttpHeaders): HttpHeaders {
    return headers.set('X-version', environment.api.ver);
  }

  protected setPlatform(headers: HttpHeaders): HttpHeaders {
    return headers.set('X-App-Platform', environment.api.platform);
  }

  protected setApiName(headers: HttpHeaders): HttpHeaders {
    return headers.set('X-App-Name', environment.api.appName);
  }
}
