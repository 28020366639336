import { from, Observable } from 'rxjs';
import firebase from 'firebase';
import { map } from 'rxjs/operators';
import { User } from '@core/auth/state/auth.models';

export const convertFireUserToAuthStateModel$ = (
  user: firebase.User
): Observable<{ tk: string; user: Omit<User, 'isPro' | 'id'> }> =>
  from(user.getIdTokenResult()).pipe(
    map(({ token: tk }) => ({
      tk,
      user: {
        email: user.email,
        name: user.displayName,
        uuid: user.uid
      }
    }))
  );
