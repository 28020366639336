import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { I18nRoutes } from '@core/i18n/i18n.routes';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { ExternalRouteResolver } from '@core/utils/routing/resolvers/external-route.resolver';
import { AppLanguages } from '@core/i18n/langs-available';
import { OnlyTestUsers } from '@core/utils/dev/only-test-users.guard';

const routes: Routes = I18nRoutes([
  // external
  {
    path: 'how-it-works',
    data: {
      external: {
        [AppLanguages.ru]: 'https://closapp.notion.site/HOW-IT-WORKS-8a030c9c574e43bca346646dfd19ee83',
        [AppLanguages.en]: 'https://closapp.notion.site/HOW-IT-WORKS-8a030c9c574e43bca346646dfd19ee83',
      }
    },
    resolve: { res: ExternalRouteResolver },
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'appstore',
    data: {
      external: {
        [AppLanguages.ru]: 'https://apps.apple.com/ru/app/clos-remote-shooting/id1509034647',
        [AppLanguages.en]: 'https://apps.apple.com/us/app/clos-remote-shooting/id1509034647'
      }
    },
    resolve: { res: ExternalRouteResolver },
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'playmarket',
    data: {
      external: {
        [AppLanguages.ru]:
          'https://play.google.com/store/apps/details?id=com.unoproduction.closapp&hl=ru&gl=RU',
        [AppLanguages.en]:
          'https://play.google.com/store/apps/details?id=com.unoproduction.closapp&hl=en&gl=US'
      }
    },
    resolve: { use: ExternalRouteResolver },
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'privacypolicy',
    data: { external: 'https://closapp.space/privacypolicy' },
    resolve: { res: ExternalRouteResolver },
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'promo',
    data: { external: 'https://closapp.space/' },
    resolve: { res: ExternalRouteResolver },
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'instagram',
    data: { external: 'https://www.instagram.com/clos.app/' },
    resolve: { res: ExternalRouteResolver },
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'terms-and-conditions',
    data: { external: 'https://closapp.space/termsandconditions' },
    resolve: { res: ExternalRouteResolver },
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },

  // internal
  {
    path: '',
    loadChildren: () =>
      import(/* webpackChunkName: "main-route" */ './routes/main').then(m => m.MainModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    canLoad: [OnlyTestUsers],
    loadChildren: () =>
      import(/* webpackChunkName: "auth-route" */ './routes/auth/auth.module').then(
        m => m.AuthModule
      )
  },
  {
    path: '404',
    canLoad: [OnlyTestUsers],
    loadChildren: () =>
      import(/* webpackChunkName: "404-route" */ './routes/not-found/not-found.module').then(
        m => m.NotFoundModule
      )
  },
  // {
  //   path: 'payment',
  //   canLoad: [OnlyTestUsers],
  //   loadChildren: () =>
  //     import('./routes/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
  // },
  {
    path: 'rooms',
    canActivate: [AuthGuard],
    canLoad: [OnlyTestUsers],
    loadChildren: () =>
      import(/* webpackChunkName: "rooms-route" */ './routes/rooms/rooms.module').then(
        m => m.RoomsModule
      )
  },
  {
    path: 'upload',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(/* webpackChunkName: "upload-route" */ './routes/upload').then(m => m.UploadModule)
  },
  {
    path: 'archives',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(/* webpackChunkName: "archives-route" */ './routes/upload').then(m => m.UploadModule)
  },
  {
    path: 'loading',
    loadChildren: () => import('./routes/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'welcome',
    redirectTo: ''
  },
  {
    path: 'gallery/:roomId',
    redirectTo: 'archives/:roomId'
  },
  {
    path: 'gallery',
    redirectTo: ''
  },
  {
    path: '_legacy/gallery',
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/gallery/gallery.module').then(m => m.GalleryModule)
  },
  {
    path: '_legacy/welcome',
    canActivate: [AuthGuard],
    canLoad: [OnlyTestUsers],
    loadChildren: () => import('./routes/welcome/welcome.module').then(m => m.WelcomeModule)
  },
  { path: '**', redirectTo: '404' }
]);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
