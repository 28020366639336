<section class="info">
  <h1 class="info__header">Welcome to CLOS</h1>
  <p class="info__title">{{ title }}</p>
  <button class="info__actions" (click)="onAction()">
    <svg class="gallery__close-icon svg-icon" width="20" height="20" aria-hidden="true">
      <use xlink:href="/assets/sprites/sprite.svg#close"></use>
    </svg>
  </button>
  <div class="download-btn">
    <a
      href="https://www.google.com/chrome/?brand=BNSD&gclid=EAIaIQobChMI8bOJlcLp8AIVm94YCh12hQDlEAAYASAAEgKZ9PD_BwE&gclsrc=aw.ds"
      class="btn-g btn-g_blue"
      (click)="onAction()"
    >
      DOWNLOAD CHROME
    </a>
  </div>
</section>
