import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { AppLanguages } from './langs-available';
import { map, tap } from 'rxjs/operators';
import { I18nRootLoader } from '@core/i18n/i18n.root-loader';
import { IS_BROWSER } from '@core/browser/tokens/is-browser';

@Injectable({
  providedIn: 'root'
})
export class I18nRootGuard implements CanActivate {
  private isBrowser = inject(IS_BROWSER);

  private document = inject(DOCUMENT);

  private request = inject(REQUEST, {
    optional: true
  });

  constructor(private translate: TranslateService) {}

  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
    const localeFromUrl = route.routeConfig.path;
    const shouldUse = localeFromUrl in AppLanguages ? localeFromUrl : this.detectUserLang();

    this.document.documentElement.lang = shouldUse;

    return combineLatest([
      this.translate.use(shouldUse),
      I18nRootLoader.getTranslation(shouldUse).pipe(
        tap(l => this.translate.setTranslation(shouldUse, l, true))
      )
    ]).pipe(map(() => true));
  }

  private detectUserLang(): AppLanguages {
    let locale: string;
    if (this.isBrowser) {
      locale = this.translate.getBrowserLang()?.toLowerCase();
    }

    if (!this.isBrowser) {
      const [mainLang] = (this.request?.headers['accept-language'] || '').split(',');
      [locale] = (mainLang || '').toLocaleLowerCase().split('-');
    }

    return locale in AppLanguages ? AppLanguages[locale] : AppLanguages.en;
  }
}
