import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AlertAnswer,
  AlertRequest,
  ConfirmAnswer,
  ConfirmRequest,
  InformMessage,
  InformRequest,
  OpenRequest,
  UploadStatus,
  ViewPhoto,
  ViewPhotoRequest
} from '../models';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { LazyPart } from '../lazy/types';
import { PopupLoader } from './popup-loader';

@Injectable()
export class PopupService {
  constructor(private loader: PopupLoader) {}

  alert(req: Partial<AlertRequest>): Observable<AlertAnswer> {
    return this.getLazyPart$().pipe(
      switchMap(({ alert }) =>
        this.open({
          wrapped: false,
          type: alert,
          inputs: req
        })
      )
    );
  }

  informMessageToUseChrome(req: Partial<InformRequest>): Observable<InformMessage> {
    return this.getLazyPart$().pipe(
      switchMap(({ informMessage }) =>
        this.open({
          wrapped: false,
          type: informMessage,
          inputs: req
        })
      )
    );
  }

  confirm(req: Partial<ConfirmRequest>): Observable<ConfirmAnswer> {
    return this.getLazyPart$().pipe(
      switchMap(({ confirm }) =>
        this.open({
          wrapped: false,
          type: confirm,
          inputs: req
        })
      )
    );
  }

  viewPhoto(req: Partial<ViewPhotoRequest>): Observable<ViewPhoto> {
    return this.getLazyPart$().pipe(
      switchMap(({ viewPhoto }) =>
        this.open({
          wrapped: true,
          type: viewPhoto,
          inputs: req
        })
      )
    );
  }

  uploadStatus(): Observable<UploadStatus> {
    return this.getLazyPart$().pipe(
      switchMap(({ uploadStatus }) =>
        this.open({
          wrapped: true,
          type: uploadStatus
        })
      )
    );
  }

  open<D>(req: OpenRequest<D>): Observable<D | void> {
    return this.getLazyPart$().pipe(
      switchMap(({ renderer }) => {
        const id = renderer.queue.push(req);
        return renderer.resolve.pipe(
          filter(result => result.id === id),
          map(({ response }) => response),
          take(1)
        );
      })
    );
  }

  private getLazyPart$(): Observable<LazyPart> {
    return this.loader.getLazyPart$();
  }
}
