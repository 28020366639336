import { User } from './auth.models';

export class SignIn {
  static readonly type = '[Auth] Sign In';
  constructor(public payload: { tk: string; user: User }) {}
}

export class SignOut {
  static readonly type = '[Auth] Sign Out';
}

export class PatchUser {
  static readonly type = '[Auth] Patch User';
  constructor(public payload: Omit<Partial<User>, 'uuid'>) {}
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
  constructor(public payload: { tk: string }) {}
}
