import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { map, switchMap } from 'rxjs/operators';
import { AuthStateModel } from '@core/auth/state/auth.state';
import { convertFireUserToAuthStateModel$ } from './auth.service.helpers';
import { AuthApiService } from './auth-api.service';
import { User } from '@core/auth/state/auth.models';

export enum AuthErrors {
  authNotCompleted
}

type AuthResponse = Observable<Pick<AuthStateModel, 'tk' | 'user'>>;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private fireAuth: AngularFireAuth, private api: AuthApiService) {}

  signInWithGoogle(): AuthResponse {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      return this.signInWithPopup(provider);
    } catch (err) {
      return throwError(err);
    }
  }

  singInWithApple(): AuthResponse {
    try {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      return this.signInWithPopup(provider);
    } catch (err) {
      return throwError(err);
    }
  }

  refreshToken(): Observable<string> {
    return this.fireAuth.authState.pipe(switchMap(s => s.getIdToken(true)));
  }

  private signInWithPopup(provider: firebase.auth.AuthProvider): AuthResponse {
    return from(this.fireAuth.signInWithPopup(provider)).pipe(
      switchMap(({ user }) => {
        if (!user) {
          return throwError(AuthErrors.authNotCompleted);
        }
        return convertFireUserToAuthStateModel$(user);
      }),
      switchMap(state => this.loginToApi(state))
    );
  }

  private loginToApi(source: { tk: string; user: Omit<User, 'isPro' | 'id'> }): AuthResponse {
    return this.api.login(source).pipe(
      map(({ is_pro: isPro, name, _id: id }) => ({
        ...source,
        user: {
          ...source.user,
          id,
          name,
          isPro
        }
      }))
    );
  }

  signOut(): Observable<void> {
    return from(this.fireAuth.signOut());
  }
}
