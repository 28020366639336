import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoomsApiModule } from '../rooms-api.module';

export interface AttachingFileInfo {
  name: string;
  size: number;
  retouch: boolean; // ???
  type: string; // mime-type, e.g. - image/jpg
}

export interface AttachFilesDTO {
  roomId: string;
  fileListInfo: AttachingFileInfo[];
}

export interface GetFilesDTO {
  roomId: string;
  retouch: boolean;
  limit: number;
  skip: number;
}

export interface RateFileDTO {
  roomId: string;
  fileName: string;
  score: number;
}

export interface RemoveFileDTO {
  roomId: string;
  filesIds: string[];
}

export interface RoomFileApiModel {
  _id: string;
  name: string;
  preview_name: string;
  size: number;
  uploaded: boolean;
  preview_uploaded: boolean;
  uploader_id: string;
  presigned_url: string;
  presigned_preview_url: string;
  score: number;
  retouch: boolean;
  retouch_source: string;
  video: boolean;
  checked: boolean;
}

export interface RoomSetPriceInputDTO {
  roomId: string;
  albumPrice?: number;
  photoPrice?: number;
}

@Injectable({
  providedIn: RoomsApiModule
})
export class RoomApiService {
  private baseUrl = `${environment.api.baseUrl}/v1/room`;

  constructor(private http: HttpClient) {}

  /**
   * @return - key-value map where "key" is a File Name and "value" - File ID
   * @example-response {
   *  "my-photo.jpg": "...ID",
   *  "my-photo-2.jpg": "...ID-2"
   * }
   */
  createFileEntities(opts: AttachFilesDTO): Observable<Record<string, string>> {
    const { roomId, fileListInfo } = opts;
    return this.http
      .post<{ list: Record<string, string> }>(this.baseUrl + `/${roomId}/images`, fileListInfo)
      .pipe(map(({ list }) => list));
  }

  getFilesEntities(params: GetFilesDTO): Observable<RoomFileApiModel[]> {
    const { roomId, retouch, skip, limit } = params;
    return this.http
      .get<{ images: RoomFileApiModel[] }>(this.baseUrl + `/${roomId}/images`, {
        params: {
          retouch: `${retouch}`,
          limit: `${limit}`,
          skip: `${skip}`
        }
      })
      .pipe(map(({ images }) => images));
  }

  rateFile(params: RateFileDTO) {
    const { roomId, fileName, score } = params;
    return this.http.post<unknown>(`${this.baseUrl}/${roomId}/images/rating`, {
      image_name: fileName,
      score
    });
  }

  removeFiles(params: RemoveFileDTO): Observable<unknown> {
    const { roomId, filesIds } = params;
    return this.http.request('delete', this.baseUrl + `/${roomId}/images`, {
      body: {
        ids: filesIds
      }
    });
  }

  setRoomPrice(input: RoomSetPriceInputDTO): Observable<unknown> {
    const { albumPrice, roomId, photoPrice } = input;
    return this.http.post(`${this.baseUrl}/${roomId}/prices`, {
      ...(albumPrice ? { album_price: albumPrice } : {}),
      ...(photoPrice ? { photo_price: photoPrice } : {})
    });
  }
}
