import { inject, Injectable } from '@angular/core';
import { Actions, ActionType, createSelector, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { ActionWithError, TrackableAction } from './types';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActionContext, ActionStatus } from '@ngxs/store/src/actions-stream';

export interface ProcessingStateModel<T = unknown, E extends Error = Error> {
  completeWithError: ActionWithError<T, E>[];
  beingPrepared: T[];
}

@State<ProcessingStateModel>({
  name: 'processing',
  defaults: {
    completeWithError: [],
    beingPrepared: []
  }
})
@Injectable({
  providedIn: 'root'
})
export class ProcessingState implements NgxsOnInit {
  static beingPrepared = createSelector(
    [ProcessingState],
    (state: ProcessingStateModel) => state.beingPrepared
  );

  static completedWithError = createSelector(
    [ProcessingState],
    (state: ProcessingStateModel) => state.completeWithError
  );

  static beingPreparedOf<T = unknown>(scope: ActionType[]) {
    return createSelector([ProcessingState], (state: ProcessingStateModel<T>) =>
      state.beingPrepared.filter(target => ProcessingState.isActionInScope(scope, target))
    );
  }

  static hasBeingPreparedOf(scope: ActionType[]) {
    return createSelector([ProcessingState.beingPreparedOf(scope)], actions => !!actions.length);
  }

  static completedWithErrorOf<T = unknown>(scope: ActionType[]) {
    return createSelector(
      [ProcessingState.completedWithError],
      (failedActions: ActionWithError<T>[]) =>
        failedActions.filter(target => ProcessingState.isActionInScope(scope, target.action))
    );
  }

  static hasCompletedWithErrorOf = (scope: ActionType[]) =>
    createSelector(
      [ProcessingState.completedWithErrorOf(scope)],
      (actions: ActionType[]) => !!actions.length
    );

  protected actions$ = inject(Actions);

  protected ctx?: StateContext<ProcessingStateModel>;

  ngxsOnInit(ctx: StateContext<ProcessingStateModel>): void {
    this.ctx = ctx;
  }

  removeActionWithErrorBy(predictFn: (action: ActionWithError) => boolean) {
    this.ctx.setState(patch({ completeWithError: removeItem(predictFn) }));
  }

  connect(): Observable<ActionContext> {
    return this.actions$.pipe(
      filter(({ action }) => (action as TrackableAction).track),
      tap(({ action, status, error }) => {
        if (status === ActionStatus.Dispatched) {
          this.ctx.setState(patch({ beingPrepared: append([action]) }));

          return;
        }

        this.ctx.setState(
          patch({
            beingPrepared: removeItem(storedAction => storedAction === action),
            ...(status === ActionStatus.Errored
              ? { failedActions: append([{ action, error }]) }
              : {})
          })
        );
      })
    );
  }

  private static isActionInScope(scope: ActionType[], target: unknown) {
    return !!scope.find(
      action =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
        target instanceof <any>action || (target as ActionType)?.type === action.type
    );
  }
}
