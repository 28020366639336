import { Inject, Injectable } from '@angular/core';
import { PopupService } from '@shared/popup/services/popup.service';
import { IS_BROWSER } from '@core/browser/tokens/is-browser';
import { LOOKED_INFO_MESSAGE } from '@shared/popup/lazy/components/inform-message/inform-message.component';

@Injectable({
  providedIn: 'root'
})
export class InformToUseChromeService {
  constructor(
    private readonly popup: PopupService,
    @Inject(IS_BROWSER) private isBrowser: boolean
  ) {}

  init(): void {
    if (this.isBrowser && !InformToUseChromeService.detectIsChrome()) {
      if (localStorage.getItem(LOOKED_INFO_MESSAGE)) {
        return;
      } else {
        this.showInformMessage();
      }
    }
  }

  private showInformMessage(): void {
    this.popup
      .informMessageToUseChrome({
        title:
          'If you came across any troubles while working with our product, ' +
          'we suggest using CLOS Web' +
          ' version on Chrome. We can guarantee smooth work of all the ' +
          'features only on this browser.'
      })
      .subscribe();
  }

  private static detectIsChrome(): boolean {
    return navigator.userAgent.indexOf('Chrome') !== -1;
  }
}
