import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { RoomApiModelStatus, RoomApiModelType } from './rooms-api.service';
import { RoomsApiModule } from '../rooms-api.module';

export interface RoomServerButton {
  name: string;
  disabled: boolean;
}

export interface RoomArchiveApiModel {
  _id: string;
  name: string;
  type: RoomApiModelType;
  status: RoomApiModelStatus;
  owner_id: string;
  owner_name: string;
  photograph_id: string;
  model_ids: string[];
  participants: string[];
  logo: string;
  logo_name: string;
  date: string;
  image_uploaded_count: number;
  image_count: number;
  retouch_image_count: number;
  dropbox_image_count: number;
  local_image_count: number;
  inner_gallery_images_count: number;
  video_count: number;
  is_owner: boolean;
  archive_link: string;
  payment_link: string;
  is_paid_owner: boolean;
  is_prices_created: boolean;
  is_available_for_all: boolean;
  avatars: {};
  users: {
    [userId: string]: {
      _id: string;
      name: string;
      avatar: string;
    };
  };
  buttons: RoomServerButton[];
  album_price?: number; // in cents
  photo_price?: number; // in cents
  expire_at?: string;
}

@Injectable({
  providedIn: RoomsApiModule
})
export class RoomsArchivesApiService {
  private readonly baseUrl = `${environment.api.baseUrl}/v1/room`;

  constructor(private http: HttpClient) {}

  getRoomDetails(roomId: string): Observable<RoomArchiveApiModel> {
    return this.http
      .get<{ data: RoomArchiveApiModel }>(`${this.baseUrl}/${roomId}/details`)
      .pipe(map(({ data }) => data));
  }

  getAll(): Observable<RoomArchiveApiModel[]> {
    return this.http
      .get<{ data: RoomArchiveApiModel[] }>(this.baseUrl + '/my')
      .pipe(map(({ data }) => data));
  }

  bulkRemoveById(archiveIds: string[]): Observable<unknown> {
    return this.http.delete(this.baseUrl, {
      body: {
        ids: archiveIds
      }
    });
  }
}
