import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import * as express from 'express';
import { Store } from '@ngxs/store';
import { isPrerenderProcess } from '@core/utils/dev/is-prerender-process';
import { isSignedInOnServerByCookies } from '../helpers';
import { Navigate } from '@ngxs/router-plugin';
import { QUERY_PARAMS_MAP } from '@core/utils/routing/query-params.map';
import { AuthState } from '../state/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REQUEST) private request: express.Request,
    private store: Store
  ) {}

  canActivate(_, { url }: RouterStateSnapshot): boolean {
    const isSignedIn = this.isSignedIn();

    if (isSignedIn) {
      return true;
    }

    this.redirectToAuth(url);
    return false;
  }

  isSignedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this.store.selectSnapshot(AuthState.isSignedIn);
    }

    if (isPlatformServer(this.platformId)) {
      if (isPrerenderProcess(this.request)) {
        return true;
      }

      return isSignedInOnServerByCookies(this.request.headers);
    }

    return false;
  }

  redirectToAuth(redirectTo: string): void {
    this.store.dispatch(
      new Navigate(['/login'], {
        [QUERY_PARAMS_MAP.redirectTo]: redirectTo
      })
    );
  }
}
