import { InjectionToken } from '@angular/core';

export interface Participant {
  id: string;
}

export interface ParticipantStore {
  get(): Participant | null;
}

export const PARTICIPANT_STORE_TK = new InjectionToken<ParticipantStore>('PARTICIPANT_STORE_TK');
