import { AUTH_COOKIE_KEY, AuthCookieState } from './const';
import { parseCookies } from '@core/utils/http/helpers';

export const buildAuthCookie = (val: AuthCookieState): string => `${AUTH_COOKIE_KEY}=${val}`;

export const isSignedInOnServerByCookies = (headers: { cookie?: string }): boolean => {
  if (typeof headers.cookie !== 'string' || !headers.cookie) {
    return false;
  }

  const cookies = parseCookies(headers.cookie);

  return `${cookies[AUTH_COOKIE_KEY]}` === `${AuthCookieState.signedIn}`;
};
