import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Inject } from '@angular/core';
import { AsPopup, InformMessage, InformRequest } from '@shared/popup/models';
import { IS_BROWSER } from '@core/browser/tokens/is-browser';

export const LOOKED_INFO_MESSAGE = 'looked-info';

@Component({
  selector: 'app-inform-message',
  templateUrl: './inform-message.component.html',
  styleUrls: ['./inform-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformMessageComponent implements AsPopup<InformMessage>, InformRequest, OnInit {
  result = new EventEmitter<InformMessage>();
  title: string;
  constructor(@Inject(IS_BROWSER) private isBrowser: boolean) {}

  ngOnInit(): void {}

  focus(): void {}

  onAction(): void {
    if (this.isBrowser) {
      localStorage.setItem(LOOKED_INFO_MESSAGE, 'true');
    }
    this.result.emit();
  }
}
