import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { User } from '../state/auth.models';
import { map, switchMap } from 'rxjs/operators';

export interface AuthApiRes {
  _id: string;
  is_pro: false;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  login({ tk, user }: { tk: string; user: Omit<User, 'isPro' | 'id'> }): Observable<AuthApiRes> {
    const loginUrl = `${environment.api.baseUrl}/auth/login`;
    const options = {
      headers: { Authorization: `Bearer ${tk}` }
    };

    return this.http.post<AuthApiRes>(loginUrl, {}, options).pipe(
      switchMap((res: AuthApiRes) => {
        if (res.name !== '') {
          return of(res);
        }
        const patchUrl = `${environment.api.baseUrl}/v1/user`;
        const patch = { name: user.name };

        return this.http
          .put<{ data: AuthApiRes }>(patchUrl, patch, options)
          .pipe(map((response: { data: AuthApiRes }) => response.data));
      })
    );
  }
}
